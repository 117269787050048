// State
export const state = () => ({
    promotions: [],
    availablePromotions: [],
    promotion: {},
    availablePromotion: {},
    loading: false,
    activePromotion: {},
    endPromotion: [],
    rollingReward: {},
    histories: {},
})

// Actions
export const actions = {
    // set promotion
    setPromotion({ commit }, data) {
        commit('setPromotion', data)
    },

    // get promotion in promotion page
    async getAvailablePromotions({ commit, rootState }) {
        commit('setLoading', true)
        this.commit('settings/setCancelToken', {
            promotion: this.$axios.CancelToken.source(),
        })
        try {
            const response = await this.$axios.get(
                `/promotions/available/${rootState.settings.defaultCurrency}`,
                {}
            )
            commit('setAvailablePromotions', response.data)
        } catch (error) {
        } finally {
            commit('setLoading', false)
        }
    },
    // get promotion
    async getPromotions({ commit, rootState }) {
        commit('setLoading', true)
        this.commit('settings/setCancelToken', {
            promotion: this.$axios.CancelToken.source(),
        })
        try {
            const response = await this.$axios.get(
                `/promotions?currency=${rootState.settings.defaultCurrency}`,
                {
                    cancelToken:
                        this.state.settings.cancelToken.promotion.token,
                }
            )
            commit('setPromotions', response.data)
        } catch (error) {
        } finally {
            commit('setLoading', false)
        }
    },
    async claimPromotion({ commit }, params) {
        commit('setLoading', true)
        try {
            await this.$axios.post(`/promotions/${params}`)
            return true
        } catch (error) {
            return false
        } finally {
            commit('setLoading', false)
        }
    },
    async activePromotion({ commit }, params) {
        this.commit('settings/setCancelToken', {
            activePromotion: this.$axios.CancelToken.source(),
        })
        try {
            const response = await this.$axios.get(`/promotions/active`, {
                cancelToken:
                    this.state.settings.cancelToken.activePromotion.token,
            })
            commit('setActivePromotion', response.data)
            return true
        } catch (error) {}
    },
    async endPromotion({ commit }, params) {
        this.commit('settings/setCancelToken', {
            endPromotion: this.$axios.CancelToken.source(),
        })
        try {
            const response = await this.$axios.get(`/promotions/ended`, {
                cancelToken: this.state.settings.cancelToken.endPromotion.token,
            })
            commit('setEndPromotion', response.data)
            return true
        } catch (error) {
            return false
        }
    },
    async rollingReward({ commit }) {
        this.commit('settings/setCancelToken', {
            rollingReward: this.$axios.CancelToken.source(),
        })
        try {
            const response = await this.$axios.get(`/rolling-reward/rolling`, {
                cancelToken:
                    this.state.settings.cancelToken.rollingReward.token,
            })
            commit('setRollingReward', response.data)
            return true
        } catch (error) {
            return false
        }
    },
    async histories({ commit }, params) {
        this.commit('settings/setCancelToken', {
            promotionHistory: this.$axios.CancelToken.source(),
        })
        try {
            const response = await this.$axios.get(`/promotions/history`, {
                params,
                cancelToken:
                    this.state.settings.cancelToken.promotionHistory.token,
            })
            commit('setHistory', response.data)
            return true
        } catch (error) {
            return false
        }
    },
    async claimPromotionCode(
        { commit, dispatch },
        { code, walletPopupStatus }
    ) {
        const uri = `/promotion-codes/claim/${code}`
        const encoded = encodeURI(uri)
        let header = {}
        if (walletPopupStatus) {
            header = {
                headers: {
                    'wallet-popup-status': walletPopupStatus,
                },
            }
        }
        try {
            const response = await this.$axios.get(encoded, header)
            if (
                response.headers['display-popup'] === 'false' ||
                response.headers['display-popup'] === false
            ) {
                dispatch('player/totalBalance', {}, { root: true })
            }
            commit('setHistory', response.data)
            return {
                ...response.data,
                display_popup: response.headers['display-popup'],
            }
        } catch (error) {
            return false
        }
    },

    async checkPromotionWalletPopup({ _ }, id) {
        try {
            const response = await this.$axios.get(
                `promotions/claim/wallet-popup/${id}`
            )
            return response.data
        } catch (error) {
            return false
        }
    },
}

// Mutations
export const mutations = {
    setPromotions: (state, val) => {
        state.promotions = val
    },
    setAvailablePromotions: (state, val) => {
        state.availablePromotions = val
    },
    setAvailablePromotion: (state, val) => {
        state.availablePromotion = val
    },
    setPromotion: (state, val) => {
        state.promotion = val
    },
    setLoading: (state, val) => {
        state.loading = val
    },
    setActivePromotion: (state, val) => {
        state.activePromotion = val
    },
    setEndPromotion: (state, val) => {
        state.endPromotion = val
    },
    setRollingReward: (state, val) => {
        state.rollingReward = val
    },
    setHistory: (state, val) => {
        state.histories = val
    },
}

// Getters
export const getters = {
    promotions: (state) => {
        return state.promotions.filter((el) => el.is_visible === true)
    },
    availablePromotions: (state) => {
        return state.availablePromotions
    },
    availablePromotion: (state) => {
        return state.availablePromotion
    },
    promotion: (state) => {
        return state.promotion
    },
}
