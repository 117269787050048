// State
export const state = () => ({
    isApp: { detected: false, type: '' },
})

// Actions
export const actions = {
    checkIsApp({ commit }) {
        const isIOSWebClip =
            window.navigator.standalone ||
            window.matchMedia('(display-mode: standalone)').matches
        const isAndroidApp = window.navigator.userAgent.includes('WLApp/1.0.0')

        if (isIOSWebClip) {
            commit('setIsApp', { detected: true, type: 'ios' })
        } else if (isAndroidApp) {
            commit('setIsApp', { detected: true, type: 'android' })
        } else {
            commit('setIsApp', { detected: false, type: '' })
        }
    },
}

// Mutations
export const mutations = {
    setIsApp: (state, v) => {
        state.isApp = v
    },
}
