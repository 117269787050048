
import gameMenu from '~/mixins/common/pages/game-menu'
import cms from '~/mixins/common/cms'
import playGames from '~/mixins/common/play-games'
export default {
    name: 'GameSubmenu',
    mixins: [gameMenu, cms, playGames],
    props: {
        submenu: {
            type: Object,
            required: true,
            default: () => {},
        },
    },
}
