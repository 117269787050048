export default async ({ app, req, $cookie }, inject) => {
	if (process.client) {
		try {
			await app.store.dispatch('settings/getDefaultCurrency')

			// Use cookie values if available
			const currency = $cookie?.get('currency')
			const country = $cookie?.get('country')

			/** In the API,
				* set currency by the API currency into cookies
				* commit setDefaultCurrency
				* set defaultCountry into cookies
			*/
			if (currency) {
				// if The Player is not logged in yet, call the api
				// if Player is already logged in, use player base currency
				app.store.commit('settings/setDefaultCurrency', currency)
			}

			if (country) {
				app.store.commit('settings/setDefaultCountry', country)
			}
		} catch (error) { }
	}
}