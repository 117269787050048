
import { mapState } from 'vuex'
export default {
    name: 'ClaimBonusButton',
    props: {
        id: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            claimLoading: false,
            checkModal: false,
            checkData: {},
        }
    },
    computed: {
        ...mapState({
            player: (state) => state.player.player,
        }),
    },
    methods: {
        async onClaim(id) {
            this.claimLoading = true
            const checkPro = await this.$store.dispatch(
                'themes/promotion/checkPromotionWalletPopup',
                id
            )
            if (~~checkPro?.withdrawal_condition_type === 1) {
                this.claimPromotion(id)
            } else if (~~checkPro?.withdrawal_condition_type === 2) {
                this.checkModal = true
                this.checkData = checkPro
            }
            this.claimLoading = false
        },
        async claimPromotion(id) {
            this.claimLoading = true
            const response = await this.$store.dispatch(
                'themes/promotion/claimPromotion',
                id
            )
            this.$emit('claimed', response)
            // get total balance
            if (response) {
                await this.$store.dispatch('player/profile')
                this.$toast.success(this.tl('claimBonusSuccessful'))
                this.checkModal = false
            }
            await this.$store.dispatch('themes/promotion/getPromotions')
            this.claimLoading = false
        },
    },
}
