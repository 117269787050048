
import { mapState, mapGetters } from 'vuex'
import cms from '~/mixins/common/cms'
import playGames from '~/mixins/common/play-games'
export default {
    mixins: [cms, playGames],
    data() {
        return {
            drawer: false,
            rateDialog: false,
        }
    },
    computed: {
        message() {
            return this.$store.state.player.messages
        },
        ...mapState({
            player: (state) => state.player.player,
            isLogin: (state) => state.settings.isLogin,
            currency: (state) => state.settings.currency,
            globalFlag: (state) => state.settings.globalFlag,
            operationSetting: (state) => state.settings.operationSetting,
            onHoldWallet: (state) => state.player.onHoldWallet,
            isApp: (state) => state.app.isApp,
        }),
        globalFlagComputed() {
            return `fi fi-${this.globalFlag?.toLowerCase()}`
        },
        ...mapGetters({
            languages: 'themes/settings/language',
            headerLogo: 'cms/headerLogo',
            footerPartnerIcon: 'cms/footerPartnerIcon',
            footerArticle4: 'cms/footerArticle4',
            footerGamingInfo: 'cms/footerGamingInfo',
        }),
        infoItems() {
            return [
                {
                    icon: 'mdi-account',
                    text: this.tl('myProfile'),
                    to: '/player/profile',
                },
                {
                    icon: 'mdi-book',
                    text:
                        this.operationSetting?.reg_flow === 5
                            ? this.tl('walletAddress')
                            : this.tl('bankDetail'),
                    to: '/payment-methods',
                },
                {
                    icon: 'mdi-message-text-fast-outline',
                    text: this.tl('message'),
                    actions: this.message.total,
                    to: '/player/messages',
                },
                {
                    icon: 'mdi-lock',
                    text: this.tl('resetPassword'),
                    to: '/reset-password',
                },
                {
                    icon: 'mdi-play-circle',
                    text: this.tl('myBonus'),
                    to: '/my-bonus',
                },
                {
                    icon: 'mdi-dots-horizontal-circle-outline',
                    text: this.tl('moreInfo'),
                    to: '/articles',
                },
            ]
        },
    },
    mounted() {
        if (this.$cookie.get('token')) {
            if (this.player.player_id) return
            this.$store.dispatch('player/profile')
            this.$store.dispatch('settings/toggleIsLogin', true)
            this.$store.dispatch('player/totalBalance')
        }
    },
    beforeDestroy() {
        this.doCancelAxiosRequest()
    },
    methods: {
        getCurrentLocale(localeCode) {
            const currentLocaleData = this.languages.filter(
                (el) => el.code === localeCode
            )
            if (currentLocaleData.length > 0) {
                return currentLocaleData[0]?.flag_icon
            }
            return null
        },
        openExchangeRateDialog() {
            this.rateDialog = true
            setTimeout(() => {
                const idExchange = document.getElementById('idExchangeRate')
                idExchange.classList.remove('v-list-item--active')
            }, 1000)
        },
    },
}
