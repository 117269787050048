
export default {
    props: {
        playerBankName: {
            type: String,
            default: '-',
        },
        bankBranch: {
            type: String,
            default: '-',
        },
        bankAccountNumber: {
            type: String,
            default: '-',
        },
        isPrimary: {
            type: Boolean,
            default: false,
        },
        accountHolderName: {
            type: String,
            default: '-',
        },
        showDisableAccount: {
            type: Boolean,
            default: false,
        },
        bankId: {
            type: String,
            default: '',
        },
    },
    computed: {
        nameLimiting() {
            if (this.accountHolderName.length > 20) {
                return this.accountHolderName?.substring(0, 20)
            }

            return this.accountHolderName
        },
    },
}
