
export default {
    props: {
        status: {
            type: Number,
            default: 0,
        },
        chip: {
            type: Boolean,
            default: true,
        },
    },
}
