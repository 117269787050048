
export default {
    name: 'ActiveBonus',
    props: {
        player: {
            type: Object,
            default: () => {},
        },
        activePromotion: {
            type: Object,
            default: () => {},
        },
    },
}
