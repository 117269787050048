
export default {
    name: 'GameLoadFailedComponent',
    methods: {
        refresh() {
            this.$emit('refresh', true)
        },
        close() {
            this.$emit('close', true)
        },
        displayError() {
            this.$emit('error', true)
        },
    },
}
