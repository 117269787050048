import { mapState, mapGetters } from 'vuex'
export default {
  computed: {
    ...mapState({
      downloadInterval: (state) => state.settings.downloadInterval,
      isApp: (state) => state.app.isApp,
    }),
    ...mapGetters({
      playerPrimaryBankAccount: 'themes/player-bank/primaryBankAccount',
    }),
    player() {
      return this.$store.state.player.player
    }
  },
  methods: {
    onChangeLanguage(code, language, currency) {

      this.$cookie.set("is_change_language", true)
      // To change recaptcha language
      const languageCodeForRecaptcha = language?.substr(0, 2)
      if (languageCodeForRecaptcha) {
        this.$recaptcha.language = languageCodeForRecaptcha
      }
      const countryCode = this.$cookie.get('country_code');
      // ? check if the selected language is the old one, stop making request to server
      if (this.handleCookie('wl_locale') === language && countryCode === code) return
      this.$cookie.set('country_code', code)
      this.handleCookie('wl_locale', language)
      if (this.$cookie?.get('currency') !== currency) {
        this.$cookie.set('currency', currency)
      }
      if (this.$cookie?.get('token')) {
        const form = { language }
        this.$store.dispatch('player/update', form)
      }
      this.$router.go(this.$router.currentRoute)
    },
    async setDefaultLanguages() {
      let params = null
      // in case player already login, use player base currency to fetch all languages
      if (this.player.currency) params = this.player.currency
      else if (this.$cookie.get('currency'))
        params = this.$cookie.get('currency')

      await this.$store.dispatch('themes/settings/getLanguage', params)
    },
    showBanner() {
      if (this.isMobileOnly() && this.requestApp && !this.isApp.detected) {
        this.$store.commit('settings/setDownloadInterval', true)
      }
    },
    copyAndOpenApp(value) {
      this.copy(value)
      // Redirect to the mobile app
      if (/Android/i.test(navigator.userAgent) && this.playerPrimaryBankAccount?.android_app_id) {
        window.location.replace(
          `intent://${this.playerPrimaryBankAccount?.android_app_id}/#Intent;scheme=https;package=${this.playerPrimaryBankAccount?.android_app_id};end`
        )
      } else if (/iPhone/i.test(navigator.userAgent) && this.playerPrimaryBankAccount?.ios_app_id) {
        window.location.replace(
          `https://apps.apple.com/app/${this.playerPrimaryBankAccount.ios_app_id}`
        )
      }
    },
  },
}
