export default {
	methods: {
		camelToSnakeCase(str) {
			if (!str) return ''
			const snakeCase = str.replace(
				/[A-Z]/g,
				(letter) => `_${letter.toLowerCase()}`
			)
			return snakeCase.substring(1, snakeCase.length)
		},
		cmsImageLink({ path, code }) {
			if (path) {
				const _url = `${this.$config.BUCKET}/${this.camelToSnakeCase(
					code
				)}/${path}`
				return _url
			}
		},
		getYoutubeVideoId(link) {
			if (!link) return ''
			try {
				const youtubeVideoId = link
					?.replace('youtu.be/', 'youtube.com/watch?v=')
					?.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/)
					?.pop()
				return youtubeVideoId
			} catch (error) {
				return ''
			}
		},
		getYoutubeVideoLink(link) {
			if (!link) return
			const youtubeId = this.getYoutubeVideoId(link)
			return `https://www.youtube.com/embed/${youtubeId}` // ?autoplay=1
		},
	}
}