
import { mapGetters } from 'vuex'
import cms from '~/mixins/common/cms'
export default {
    mixins: [cms],
    computed: {
        ...mapGetters({
            desktopBackground: 'cms/desktopBackground',
            mobileBackground: 'cms/mobileBackground',
        }),
    },
}
