
import fingerprint from '~/mixins/fingerprint'
import ReCaptcha from '~/mixins/recaptcha'
import interRegister from '~/mixins/pages/register/interRegister'
import cms from '~/mixins/common/cms'
import { PLAYER_REGEX } from '~/utils/constants'
export default {
    name: 'Theme2Register',
    mixins: [fingerprint, ReCaptcha, interRegister, cms],
    props: {
        toggle: {
            type: Function,
            default: () => {},
        },
        // to check if this component is called by Navbar or Register Page
        registerPage: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        operationSetting(value) {
            this.reg_flow = value.reg_flow
            this.playerRegistrationType = value.player_reg_id
            this.bankNameType = value.bank_name_type
        },
        bankOptionSelection(value) {
            if (value === 2) {
                this.currencies = [this.$cookie.get('currency')]
                this.form.bank_currency = this.$cookie.get('currency')
            }
        },
    },
    async mounted() {
        const countryCode = this.$cookie?.get('country_code')
        await this.countryChange(countryCode)
        this.form.reg_fingerprint = await this.visitorId()
        this.form.country_code = countryCode
        const globalReg = this.$cookie?.get('global_reg')
        this.globalReg = globalReg
    },
    methods: {
        onLoginNow() {
            if (this.registerPage) {
                this.$router.push('/login')
            } else {
                this.toggle()
            }
        },
        onClose() {
            if (this.registerPage) {
                this.$router.push('/')
            } else {
                this.$emit('input', false)
            }
        },
        async register() {
            this.$refs?.refRegister?.validate()
            if (this.formValid && this.acceptCondition) {
                if (this.form.player_id) {
                    if (!PLAYER_REGEX.test(this.form.player_id)) {
                        return
                    }
                }
                try {
                    this.registerLoading = true
                    this.form.recaptchaToken = await this.getRecaptchaToken(
                        'register'
                    )
                    this.form.reg_flow = this.reg_flow
                    if (this.playerRegistrationType !== 1) {
                        delete this.form.player_id
                    }
                    const { redirect, res } = await this.$store.dispatch(
                        'auth/registration',
                        {
                            ...this.form,
                            bankOptionSelection: this.bankOptionSelection,
                        }
                    )
                    await this.$store.dispatch(
                        'settings/supportCountryAndLanguage',
                        { fetch: true }
                    )

                    if (res) {
                        // Delay WebSocket initialization to ensure previous operations are fully completed
                        setTimeout(async () => {
                            await this.initSocketTheme()
                        }, 1000)
                        this.$router.push(redirect)
                    } else {
                        this.resetRecaptcha()
                    }
                    this.registerLoading = false
                } catch (error) {
                    this.registerLoading = false
                }
            }
        },
        onChangeBank(bankId) {
            const getSelectedBank = this.banks.find((el) => el.id === bankId)
            if (getSelectedBank?.supported_currency) {
                this.currencies = [...getSelectedBank.supported_currency]
            }
        },
    },
}
