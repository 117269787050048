
import { mapGetters } from 'vuex'
import cashier from '~/mixins/common/cashier'

export default {
    name: 'CashierComponent',
    mixins: [cashier],
    computed: {
        ...mapGetters({
            holdBalanceInstruction: 'cms/holdBalanceInstruction',
        }),
    },
}
