
export default {
    name: 'CircleComponent',
    props: {
        height: {
            type: [Number, String],
            default: 100,
        },
        width: {
            type: [Number, String],
            default: 100,
        },
        color: {
            type: String,
            default: 'rgba(0, 0, 0, 12%)',
        },
        animation: {
            type: String,
            default: 'wave',
        },
    },
    computed: {
        bindClass() {
            return `animation--${this.animation}`
        },
    },
    mounted() {
        const width =
            typeof this.width === 'number' ? `${this.width}px` : this.width
        const height =
            typeof this.width === 'number' ? `${this.height}px` : this.height
        const background = `${this.color}`
        const loader = this.$el
        loader.style.setProperty('width', width)
        loader.style.setProperty('height', height)
        loader.style.setProperty('background-color', background)
    },
}
