
import fingerprint from '~/mixins/fingerprint'
import ReCaptcha from '~/mixins/recaptcha'
import Login from '~/mixins/pages/login/login'

export default {
    mixins: [fingerprint, ReCaptcha, Login],
    props: {
        toggle: {
            type: Function,
            default: () => {},
        },
        // to check if this component is called by Navbar or Login Page
        loginPage: {
            type: Boolean,
            default: false,
        },
    },
    created() {
        this.changeLoginType(null)
    },
    async mounted() {
        const username = this.$cookie.get('un')
        const phoneNumber = this.$cookie.get('pn')
        const loginOption = this.$cookie.get('login_option')
        if (username) this.form.player_id = username
        if (phoneNumber) this.form.phone = phoneNumber
        if (loginOption && ['phone', 'username'].includes(loginOption))
            this.loginType = loginOption
        this.form.reg_fingerprint = await this.visitorId()
        const countryCode = this.$cookie?.get('country_code')

        if (this.isInternational) {
            this.country =
                countryCode === 'GO'
                    ? this?.$cookie?.get('global_reg')
                    : countryCode
        } else {
            this.country = 'LA'
        }
    },
    methods: {
        onRegisterNow() {
            if (this.loginPage) {
                this.$router.push('/register')
            } else {
                this.toggle()
            }
        },
        onClose() {
            if (this.loginPage) {
                this.$router.push('/')
            } else {
                this.$emit('input', false)
            }
        },
        changeLoginType(type) {
            this.loginType = type || this.loginType
            if (this.loginType === 'username') {
                this.isRequireRule = false
                if (!this.form.player_id) {
                    const username = this.$cookie.get('un')
                    if (!username) {
                        return
                    }
                    this.form.player_id = username
                }
            } else if (this.loginType === 'phone') {
                if (!this.form.phone) {
                    const phoneNumber = this.$cookie.get('pn')
                    if (!phoneNumber) {
                        return
                    }
                    this.form.phone = phoneNumber
                }
            }
        },
    },
}
