import { mapGetters, mapState } from 'vuex'
export default {
    data: () => ({
        messageFab: false,
        transition: 'slide-x-reverse-transition',
    }),
    computed: {
        ...mapGetters({
            rightFloatingMessageIcon: 'cms/rightFloatingMessageIcon',
            rightFloatingSupportIcon: 'cms/rightFloatingSupportIcon',
            maintenance: 'themes/settings/maintenance',
        }),
        ...mapState({
            operationSetting: (state) => state.settings.operationSetting,
        }),
        maintenance() {
            return this.operationSetting.website_maintenance ?? {}
        },
        // The floating icon is displayed on top of all components.
        // During maintenance, it must have the highest z-index to ensure visibility.
        floatingIconIndex() {
            return this.maintenance.status ? { zIndex: 999999 } : { zIndex: 6 }
        },
        displayMainFloatingIcon1() {
            // By default, include_sub_icon is either null or undefined.
            // Therefore, the condition checks if it is not explicitly set to true.
            return this.rightFloatingSupportIcon.include_sub_icon !== true
        },
        displayMainFloatingIcon2() {
            // By default, include_sub_icon is either null or undefined.
            // Therefore, the condition checks if it is not explicitly set to true.
            return this.rightFloatingMessageIcon.include_sub_icon !== true
        },
    },
    methods: {
        openNewWindow(link) {
            // Opens a new browser window with the specified link.
            // If the link does not start with "http" (case-insensitive), it prepends "http://".
            // The new window is named "Google" and is set to a width of 550px and a height of 600px.
            window.open(
                !link.toLowerCase().startsWith('http')
                    ? 'http://' + link
                    : link,
                'Google',
                'width=550,height=600'
            )
        },
    },
}
