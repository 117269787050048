export const state = () => ({
	loading: false,
	languageStrings: {},
})

export const actions = {
	async fetchLanguageStrings({ commit }, localeLang) {
		commit('setLoading', true)
		try {
			const response = await this.$axios.get('/language-strings/language/' + localeLang)
			const convertArrayToObject = Object.fromEntries(response.data.map(item => [item.code, item.string]));
			commit('setLanguageString', convertArrayToObject)
		} catch (error) {

		} finally {
			commit('setLoading', false)
		}
	},
}

export const mutations = {
	setLoading: (state, v) => {
		state.loading = v
	},
	setLanguageString: (state, v) => {
		state.languageStrings = v
	},
}

export const getters = {
	localeLanguage: state => state.languageStrings,
	tl: (state) => (code) => {
		return state.languageStrings[code] || code;
	},
}
