export default {
	middleware({ store, redirect, req, res, $config, route }) {
		try {
			const { banner_uuid: bannerUuid, player_type: playerType, origin } = store.app.context.query

			const urlRegex = /^(https?):\/\/[^\s/$.?#].[^\s]*$/i;
			const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
			const playerTypeRegex = /^[1234567890]$/;

			if (bannerUuid && playerType) {
				const cookies = []
				const expireDate = new Date();
				expireDate.setDate(expireDate.getDate() + 10); // Set expiration to 10 days from now
				const expires = `Expires=${expireDate.toUTCString()};`;

				if (urlRegex.test(origin)) {
					cookies.push(`x-banner-url=${origin};PATH=/;${expires}`);
				}
				if (uuidRegex.test(bannerUuid) && playerTypeRegex.test(playerType)) {
					cookies.push(`x-banner-id=${bannerUuid};PATH=/;${expires}`);
					cookies.push(`x-player-type=${playerType};PATH=/;${expires}`);
				}
				res.setHeader('Set-Cookie', cookies);

				const cleanUrl = route.path;

				return redirect(cleanUrl);
			}
		} catch (error) { }
	}
}