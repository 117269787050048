
import { mapState } from 'vuex'
import playGames from '~/mixins/common/play-games'
export default {
    name: 'MenubarComponent',
    mixins: [playGames],
    data: () => ({
        openCard: [],
        v: true,
    }),
    computed: {
        ...mapState({
            menubars: (state) => state.cms.menus.menus,
        }),
    },
}
