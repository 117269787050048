
import serverSide from '~/mixins/serverSide'
import Meta from '~/mixins/meta'

export default {
    // Loads server-side resources for not-founded,
    // because this.$router.push('/') will not trigger default layout to fetch server-side resources again
    // such as serving assets like favicon.ico.
    mixins: [serverSide, Meta],
    props: {
        error: {
            type: Object,
            default: null,
        },
    },
    created() {
        this.$router.push('/')
    },
}
