
export default {
    data() {
        return {
            pageNotFound: '404 Not Found',
            otherError: 'An error occurred',
        }
    },
    head() {
        const title = this.pageNotFound
        return { title }
    },
}
