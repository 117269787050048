
import { mapState, mapGetters } from 'vuex'
import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'
import { Navigation, Pagination } from 'swiper'
import 'swiper/swiper-bundle.css'
import cms from '~/mixins/common/cms'
import playGames from '~/mixins/common/play-games'
SwiperCore.use([Navigation, Pagination])
export default {
    name: 'FooterComponent',
    components: {
        Swiper,
        SwiperSlide,
    },
    mixins: [cms, playGames],
    data() {
        return {
            partnerModel: 0,
            dragXStart: 0,
        }
    },
    computed: {
        ...mapState({
            isLogin: (state) => state.settings.isLogin,
            isApp: (state) => state.app.isApp,
        }),
        ...mapGetters({
            footerLogo: 'cms/footerLogo',
            footerPartnerIcon: 'cms/footerPartnerIcon',
            footerArticle1: 'cms/footerArticle1',
            footerArticle2: 'cms/footerArticle2',
            footerArticle3: 'cms/footerArticle3',
            footerArticle4: 'cms/footerArticle4',
            footerGamingInfo: 'cms/footerGamingInfo',
            footerTermAndCondition: 'cms/footerTermAndCondition',
        }),
        lang() {
            return `${this.handleCookie('wl_locale')}-slug`
        },
    },
    methods: {
        gotoDetail(item) {
            this.$store.dispatch('detail/setCustomContent', item)
            this.$router.push(
                `/detail?component=${item?.component_content_id}&article=${item?._id}`
            )
        },
        onBack() {
            const elParent = document.getElementsByClassName('w-partner-slider')
            if (!elParent || !elParent[0]) return
            const elBack =
                elParent[0].getElementsByClassName('swiper-button-prev') //
            if (!elBack || !elBack[0]) return
            elBack[0].dispatchEvent(new MouseEvent('click'))
        },
        onNext() {
            const elParent = document.getElementsByClassName('w-partner-slider')
            if (!elParent || !elParent[0]) return
            const elNext =
                elParent[0].getElementsByClassName('swiper-button-next')
            if (!elNext || !elNext[0]) return
            elNext[0].dispatchEvent(new MouseEvent('click'))
        },
        onNextTrick() {
            if (this.partnerModel + 1 < this.footerPartnerIcon?.items?.length)
                this.partnerModel += 1
        },
        onPrevTrick() {
            if (this.partnerModel - 1 >= 0) this.partnerModel -= 1
        },
        evtDragStart(evt) {
            this.dragXStart = evt?.clientX
        },
        evtDragEnd(evt) {
            const dragXEnd = evt?.clientX
            if (dragXEnd > this.dragXStart) {
                this.onBack()
            } else if (dragXEnd < this.dragXStart) {
                this.onNext()
            }
            this.dragXStart = 0
        },
        getArticleLink(main, subItem) {
            const mainTitle = encodeURIComponent(main.title[this.lang]) || '-'
            const subTitle = encodeURIComponent(subItem.title[this.lang]) || '-'
            return `/articles/${mainTitle}/${subTitle}?main_id=${main?._id}&sub_id=${subItem?._id}`
        },
    },
}
