
// State
export const state = () => ({
	loading: false,
	gameTypes: [],
	games: { data: [] },
	gameProviders: [],
	isMaintenance: false,
	featuredGames: [],
})

// Actions
export const actions = {
	// Game types
	async gameTypes({ commit }) {
		try {
			const response = await this.$axios
				.get('/game-types')
			commit('gameTypes', response.data)
		} catch (error) {

		}
	},

	// Game providers
	async gameProviders({ commit }) {
		try {
			const response = await this.$axios
				.get('/cms/game-providers')
			commit('gameProviders', response.data)
		} catch (error) {

		}
	},

	// Games
	async games({ commit }, params) {
		commit('settings/loading', true, { root: true })
		try {
			const response = await this.$axios
				.get('/games', {
					params,
				})
			commit('games', response.data)
		} catch (error) {

		} finally {
			commit('settings/loading', false, { root: true })
		}
	},
	// Play
	async playGame({ commit, dispatch, rootState }, game) {
		if (this.$cookie.get('token')) {
      commit('settings/loading', true, { root: true })
      const language = rootState['base-settings']?.configuration?.wl_locale
			return await this.$axios
				.get('/game-link', {
          params: {
            ...game,
             language
          }
				})
				.then((response) => {
					if (response?.status !== 200) {
						return {
							message: response.data.message,
							status: false,
						}
					}
					if (response === false) return
					dispatch('player/profile', null, { root: true })

					return { status: true, isSupportIframe: response?.data?.isSupportIframe, url: response?.data?.url?.replace('http://', 'https://'), message: null }
				})
				.catch((e) => {
					commit('settings/loading', false, { root: true })
					return {
						message: e?.response?.data?.message,
						status: false,
					}
				})
				.finally(() => {
					commit('settings/loading', false, { root: true })
					return {
						message: null,
						status: false,
					}
				})
		} else {
			this.$router.push('/login')
		}
	},
}

// Mutations
export const mutations = {
	gameTypes(state, data) {
		state.gameTypes = data
	},
	gameProviders(state, data) {
		state.gameProviders = data
	},
	games(state, data) {
		state.games = data
	},
	loading(state, type) {
		state.loading = type
	},
	gameImageNotFound(state, game) {
		// state.games.data.find(o => o.id === game.id).gsc_game_image = '/no-image.jpeg'
		const found = state.games.data.find((o) => o.id === game.id)
		if (found) {
			found.gsc_game_image = '/no-image.jpeg'
			return found
		}
	},
	providerImageNotFound(state, provider) {
		state.gameProviders.find((o) => o.id === provider.id).file_path =
			'no-image.jpeg'
	},
	isMaintenance(state, type) {
		state.isMaintenance = type
	},
	featuredGames(state, value) {
		state.featuredGames = value;
	},
}

// Getters
export const getters = {
	findProvider: (state) => (providerCode) => {
		return state.gameProviders.find((o) => o.provider_code === providerCode)
	},
	gameTypes: (state, getters) => (providerCode) => {
		const provider = getters.findProvider(providerCode)
		if (provider) {
			return state.gameTypes.filter((o) =>
				provider.support_game_types.includes(o.code)
			)
		} else {
			return state.gameTypes
		}
	},
	providersWithProductCode: (state) => (productTypeCode) => {
		return state.gameProviders.filter((o) =>
			o.support_game_types.includes(productTypeCode)
		)
	},
	providersWithFilter: (state) => (providerCode, productTypeCode) => {
		const filtered = state.gameProviders.filter((o) =>
			o.support_game_types.includes(productTypeCode)
		)
		if (providerCode) {
			return filtered.filter((o) => o.provider_code === providerCode)
		} else {
			return filtered
		}
	},
	providerHasLobby: (state, getters) => (providerCode) => {
		const provider = getters.findProvider(providerCode)
		if (provider) {
			return provider.has_lobby
		} else {
			return false
		}
	},
}
