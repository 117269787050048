
import { mapState, mapGetters } from 'vuex'
import cms from '~/mixins/common/cms'
export default {
    mixins: [cms],
    computed: {
        ...mapState({
            playGameLoading: (state) => state.settings.loading,
            player: (state) => state.player.player,
            isLogin: (state) => state.settings.isLogin,
            globalFlag: (state) => state.settings.globalFlag,
            supportCountryAndLanguage: (state) =>
                state.settings.supportCountryAndLanguage,
            onHoldWallet: (state) => state.player.onHoldWallet,
            isApp: (state) => state.app.isApp,
        }),
        ...mapGetters({
            languages: 'themes/settings/language',
            headerLogo: 'cms/headerLogo',
        }),
        isCapital() {
            let capital = 0
            const nickname = this.player.nickname
            if (!nickname) return false
            for (const letter of nickname) {
                if (letter?.charCodeAt() >= 65 && letter?.charCodeAt() <= 90) {
                    capital += 1
                }
            }
            return capital >= 3
        },
    },
    mounted() {
        if (this.$cookie.get('token')) {
            if (this.player.player_id) return
            this.$store.dispatch('player/profile')
            this.$store.dispatch('settings/toggleIsLogin', true)
            this.$store.dispatch('player/totalBalance')
        }
    },
    beforeDestroy() {
        this.doCancelAxiosRequest()
    },
    methods: {
        playGameNewTab() {
            this.$nuxt.$emit('openGameNewTab')
        },
    },
}
