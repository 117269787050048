
export default {
    props: {
        avatarSize: {
            type: String,
            default: '110',
        },
        handIconSize: {
            type: String,
            default: '50',
        },
        dollarIconSize: {
            type: String,
            default: '25',
        },
        top: {
            type: String,
            default: '25px',
        },
        color: {
            type: String,
            default: 'primary--text',
        },
    },
}
