export default {
	data() {
		return {
			hover: false,
			submenuSize: 'auto',
			windowSize: {
				x: 0,
				y: 0,
			},
			failed_image: false,
		}
	},
	computed: {
		currentSubmenuIndex() {
			return this.$store.state.cms.menus.hoveredSubmenuIndex
		},
		cPicture() {
			return this.failed_image
				? '/no-image.jpeg'
				: `${this.$config.BUCKET}/assets/cms/menubar_providers/${this.submenu.image}`
		},
		isSubMenuImgActive() {
			return (menuIndex) =>
				`submenu-img ${this.currentSubmenuIndex !== menuIndex && this.currentSubmenuIndex !== null ? 'active' : ''}`;
		}

	},
	watch: {
		'windowSize.x'(value) {
			if (value > 1268 && value < 1700) {
				// ? window width size is less than 1268, called phone screen
				this.submenuSize = '250px'
			} else if (value >= 1700) {
				// ? window which has width's size is in range (2000, 1700), desktop screen but medium screen
				this.submenuSize = '250px'
			} else {
				this.submenuSize = '180px'
			}
		},
	},
	methods: {
		menuMouseOver() {
			this.$store.commit('cms/menus/setHoveredSubmenuIndex', this.submenu.index)
		},
		menuMouseLeave() {
			this.$store.commit('cms/menus/setHoveredSubmenuIndex', null)
		},
		onResize() {
			this.windowSize = { x: window.innerWidth, y: window.innerHeight }
		},
		onImageError() {
			this.failed_image = true
		},
	},
}