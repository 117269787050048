import { render, staticRenderFns } from "./FeaturedContent3.vue?vue&type=template&id=466a0014&scoped=true"
import script from "./FeaturedContent3.vue?vue&type=script&lang=js"
export * from "./FeaturedContent3.vue?vue&type=script&lang=js"
import style0 from "./FeaturedContent3.vue?vue&type=style&index=0&id=466a0014&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "466a0014",
  null
  
)

export default component.exports