
import { mapGetters, mapState } from 'vuex'
import cms from '~/mixins/common/cms'
export default {
    name: 'DownloadBanner',
    mixins: [cms],
    props: {
        downloadButtonColor: {
            type: String,
            default: '',
        },
        downloadButtonClass: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            hostname: this.$config.DOMAIN?.split('.')[0] || '',
            timeout: null,
        }
    },
    computed: {
        ...mapState({
            downloadInterval: (state) => state.settings.downloadInterval,
        }),
        ...mapGetters({
            headerLogo: 'cms/headerLogo',
        }),
        headerLogoPath() {
            return this.getLocaleLang(this.headerLogo.image_path)
                ? this.cmsImageLink({
                      path: this.getLocaleLang(this.headerLogo.image_path),
                      code: this.headerLogo.component_code,
                  })
                : null
        },
    },
    mounted() {
        this.setAutoClose()
    },
    beforeDestroy() {
        this.clearTimeout()
    },
    methods: {
        setAutoClose() {
            if (!this.downloadInterval) return
            this.timeout = setTimeout(this.closeBanner, 10000)
        },
        closeBanner() {
            this.clearTimeout()
            this.$store.commit('settings/setDownloadInterval', false)
        },
        clearTimeout() {
            clearTimeout(this.timeout)
            this.timeout = null
        },
        handleDownload() {
            this.closeBanner()
            this.$router.push('/download')
        },
    },
}
