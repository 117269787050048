
export default {
    props: {
        steppers: {
            type: Number,
            default: 1,
        },
        step1: {
            type: String,
            default: 'amountToDeposit',
        },
        step2: {
            type: String,
            default: 'verifyReceiverBankDetails',
        },
        step3: {
            type: String,
            default: 'depositConfirmInProgress',
        },
    },
}
