
import { mapState } from 'vuex'
export default {
    name: 'ClaimPromoCode',
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        code: {
            type: String,
            default: null,
        },
        data: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            claimLoading: false,
            checkData: {},
        }
    },
    computed: {
        ...mapState({
            player: (state) => state.player.player,
        }),
        checkModal: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            },
        },
    },
    methods: {
        async onSecondClaim() {
            this.claimLoading = true
            await this.$store.dispatch('themes/promotion/claimPromotionCode', {
                code: this.code?.trim(),
                walletPopupStatus: 2,
            })
            this.$toast.success(this.tl('claimPromotionCodeSucceeded'))
            this.$emit('click')
            this.claimLoading = false
        },
    },
}
