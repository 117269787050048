
import { mapGetters } from 'vuex'
export default {
  components: {},
  data() {
    return {
      checkInterest: false,
    }
  },
  computed: {
    ...mapGetters({
      topWinner: 'cms/topWinner',
      unluckyWinner: 'cms/unluckyWinner',
    }),
  },
  methods: {
    onIntersect(entries, observer) {
      const interesting = entries[0]?.isIntersecting
      if (interesting) {
        this.checkInterest = true
      }
    },
  },
}
