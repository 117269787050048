export const state = () => ({
	loading: false,
	agentFloatingIcons: {},
})

export const actions = {
	async agentFloatingIcons({ commit, rootState }) {
		commit('setLoading', true)
		try {
			const response = await this.$axios
				.get('/floating-icons', {
					headers: {
						'X-FLOATING-ICON-DOMAIN': rootState.settings.defaultWebsiteData['x-floating-icon-domain'],
					},
					params: {
						theme_code: 'theme-2',
						currency: 'THB'
					}
				})
			commit('agentFloatingIcons', response.data)
		} catch (error) {
		} finally {
			commit('setLoading', false)
		}
	},
}

export const mutations = {
	setLoading: (state, v) => {
		state.loading = v
	},
	agentFloatingIcons: (state, v) => {
		state.agentFloatingIcons = v;
	}
}

export const getters = {
	agentFloatingSupportIcon: (state, getters, rootState) => {
		const data = rootState.cms.floatingIcons.data
		return data || {};
	},
}
