// State
export const state = () => ({
    loading: false,
})

// Actions
export const actions = {
    // Get bank account holder name
    async getBankAccountHolderName({ _ }, { bankId, accountNo }) {
        try {
            const response = await this.$axios.get(
                `/players/get-bank-account-info?bank_id=${bankId}&bank_account_number=${accountNo}`
            )
            return response?.data
        } catch (error) {}
    },
    // Reset password
    async doResetPassword({ commit }, form) {
        commit('setLoading', true)
        delete form.confirm_password
        try {
            await this.$axios.put('/players/reset-password', form)
            this.$toast.success(
                this.getters['themes/language-string/tl'](
                    'changePasswordSuccessful'
                )
            )
            return true
        } catch (error) {
        } finally {
            commit('setLoading', false)
        }
    },
    // Update player profile
    async doUpdatePlayerProfile({ commit }, form) {
        commit('setLoading', true)
        const formData = JSON.parse(JSON.stringify(form))
        if (!formData.email) formData.email = null
        const items = formData?.social_info
        for (const idx in items) {
            if (!items[idx].social_media_type || !items[idx].social_media_id)
                items.splice(idx, 1)
        }
        if (!items?.length) delete formData.social_info
        delete formData.currency
        // form.phone = form.phone.replace('+', '')
        if (formData.date_of_birth) {
            formData.date_of_birth = this.$dayjs(form?.date_of_birth).format(
                'YYYY-MM-DD'
            )
        } else {
            formData.date_of_birth = null
        }
        try {
            await this.$axios.put('/players', formData)
            this.$toast.success(
                this.getters['themes/language-string/tl'](
                    'saveChangeSuccessfull'
                )
            )
            this.dispatch('player/profile')
            return true
        } catch (error) {
        } finally {
            commit('setLoading', false)
        }
    },
    // Forgot password
    async doDeleteSocial({ commit }, socialId) {
        try {
            await this.$axios.delete('/players/delete-social-media/' + socialId)
            this.$toast.success(
                this.getters['themes/language-string/tl'](
                    'deleteSocialSuccessful'
                )
            )
            return true
        } catch (error) {
            return false
        }
    },
    // Messages
    async getMessage({ commit }, params) {
        commit('setLoading', true)
        this.commit('settings/setCancelToken', {
            playerMessage: this.$axios.CancelToken.source(),
        })
        try {
            const response = await this.$axios.get(`/player-messages`, {
                params,
                cancelToken:
                    this.state.settings.cancelToken.playerMessage.token,
            })
            this.commit('player/setMessages', response.data)
        } catch (error) {
        } finally {
            commit('setLoading', false)
        }
    },
    async checkSuspendRegistration({ rootState, rootGetters }, currency) {
        // Return true if the currency is suspended, false for active
        // This route is available for Lao Client only
        if (!rootState.themes.settings.isInternational) {
            try {
                const response = await this.$axios.get(
                    `/website-setting/suspend-registration/${currency}`
                )
                return response.data
            } catch (error) {}
        }
    },
    async checkRegistrationSetting({ _ }, params) {
        try {
            const response = await this.$axios.get(
                `/website-setting/registration-setting/${params}`
            )
            return response.data
        } catch (error) {}
    },
}

// Mutations
export const mutations = {
    setLoading(state, data) {
        state.loading = data
    },
}

// Getters
export const getters = {
    loading: (state) => state.loading,
}
