
import { mapGetters } from 'vuex'
export default {
    name: 'TermAndConditions',
    data: () => ({
        subId: '',
        listExpandModel: {},
        expandHeaderModel: {},
    }),
    head() {
        return {
            title: this.getLocaleLang(this.content?.title, true),
            meta: [
                {
                    hid: 'og:title',
                    name: 'og:title',
                    content: this.getLocaleLang(this.content?.title), // This can be overridden by page-level meta tags.
                },
            ],
        }
    },
    computed: {
        isMobile() {
            return this.$store.state.themes.settings.isMobile
        },
        ...mapGetters({
            detailArticles: 'cms/detailArticles',
            footerTermAndCondition: 'cms/footerTermAndCondition',
        }),
        loading() {
            return this.detailArticles.length === 0
        },
        content() {
            const { title, slug } = this.$route.params
            const mainId = this.$route.query?.main_id
            const subId = this.$route.query?.sub_id
            if (title && slug) {
                const findMatchArticleContent = this.detailArticles.find(
                    (el) => {
                        if (el.title) {
                            let isMatch = false
                            for (const key in el.title) {
                                if (key.includes('-slug')) {
                                    if (el._id === mainId) {
                                        isMatch = true
                                    }
                                }
                            }
                            return isMatch
                        }
                        return false
                    }
                )

                if (!findMatchArticleContent) return {}

                const findMatchArticleContentItem =
                    findMatchArticleContent.items.find((el) => {
                        if (el.title) {
                            let isMatch = false
                            for (const key in el.title) {
                                if (key.includes('-slug')) {
                                    if (
                                        el.title[key]?.toLowerCase() ===
                                        slug?.toLowerCase()
                                    ) {
                                        if (el._id === subId) {
                                            isMatch = true
                                        }
                                    }
                                }
                            }
                            return isMatch
                        }
                        return false
                    })
                return findMatchArticleContentItem
            }
            if (this.detailArticles.length) {
                const title = this.detailArticles[0]?.title
                const description = this.detailArticles[0].items[0].description
                return {
                    title,
                    description,
                }
            }
            return {}
        },
        lang() {
            return `${this.handleCookie('wl_locale')}-slug`
        },
    },
    watch: {
        $route: {
            immediate: true, // Run the logic when the component is created
            handler(newRoute) {
                const mainId = newRoute.query?.main_id
                const subId = newRoute.query?.sub_id

                this.subId = subId
                this.listExpandModel[mainId] = true
                this.expandHeaderModel = { 0: 0 }
            },
        },
        // Set the expand list to be default value
        // Default value is gonna be the first of each main article and sub items in the article
        detailArticles(value) {
            if (value && value.length) {
                const { title, slug } = this.$route.params
                if (!title && !slug) {
                    const mainArticle = value[0]?.title
                    const subArticle = value[0]?.items[0]?.title

                    const title = mainArticle[this.lang]
                    const slug = subArticle[this.lang]
                    if (title && slug) {
                        const mainId = this.$route.query?.main_id
                        const subId = this.$route.query?.sub_id

                        this.subId = subId
                        this.listExpandModel[mainId] = true
                    }
                }
            }
        },
    },
    destroyed() {
        this.$store.dispatch('detail/setCustomContent', {})
        if (this.clickHeaderTimeout) {
            clearTimeout(this.clickHeaderTimeout)
        }
    },
    methods: {
        setDetail(item, mainId, subId, mainTitle) {
            const title = mainTitle[this.lang]
            const slug = item?.title[this.lang]
            this.$store.dispatch('detail/setCustomContent', item)
            this.$router.push({
                params: { title: title || '-', slug: slug || '-' },
                query: { main_id: mainId, sub_id: subId },
            })
        },
        getPageYOffsetById(elementId) {
            const element = document.getElementById(elementId)
            if (element) {
                const rect = element.getBoundingClientRect()
                return rect.top + window.pageYOffset
            }
            return null // Element with the specified id not found
        },
        onClickHeader(headerId) {
            if (this.clickHeaderTimeout) {
                clearTimeout(this.clickHeaderTimeout)
            }
            this.clickHeaderTimeout = setTimeout(() => {
                const offset = this.getPageYOffsetById(headerId)
                window.scrollTo({
                    top: offset - 100 + 24,
                    behavior: 'smooth',
                })
                clearTimeout(this.clickHeaderTimeout)
            }, 450)
        },
        toggleListGroup(id) {
            this.listExpandModel[id] = !this.listExpandModel[id]
        },
    },
}
