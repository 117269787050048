
import { mapGetters } from 'vuex'
import cms from '~/mixins/common/cms'
export default {
    mixins: [cms],
    data() {
        return {
            rootTitle: 0,
            button: 0,
            contentItem: {},
        }
    },
    computed: {
        ...mapGetters({
            introductionVideoContents: 'cms/introductionVideoContents',
        }),
    },
    watch: {
        introductionVideoContents(value) {
            // first check
            if (value.length > 0) {
                if (value[0].items.length > 0) {
                    this.contentItem = value[0]?.items[0]
                }
            }
        },
    },
}
