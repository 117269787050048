
import CircleLoader from './Pices/Circle.vue'
import SquareLoader from './Pices/Straight.vue'
export default {
    name: 'LoaderComponent',
    components: {
        CircleLoader,
        SquareLoader,
    },
    props: {
        type: {
            type: String,
            default: 'rect',
        },
        size: {
            type: [Number, String],
            default: '',
        },
        animation: {
            type: String,
            default: 'wave',
        },
        height: {
            type: [Number, String],
            default: '20px',
        },
        width: {
            type: [Number, String],
            default: '100%',
        },
        color: {
            type: String,
            default: 'rgba(0, 0, 0, 12%)',
        },
        waveColor: {
            type: String,
            default: 'rgba(255, 255, 255, 10%)',
        },
        rounded: {
            type: Boolean,
            default: false,
        },
        radius: {
            type: [Number, String],
            default: 4,
        },
    },
    computed: {
        loaderWidth() {
            if (this.size) {
                return this.size
            } else {
                return this.width
            }
        },
        loaderHeight() {
            if (this.size) {
                return this.size
            } else {
                return this.height
            }
        },
    },
    mounted() {
        if (this.waveColor) {
            this.$el.style.setProperty('--gradient-color', this.waveColor)
        }
    },
}
