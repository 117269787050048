
import { mapGetters, mapState } from 'vuex'
export default {
    name: 'MaintenanceComponent',
    data: () => ({
        dialog: false,
        maintenance: {},
    }),
    computed: {
        ...mapGetters({
            maintenanceGetter: 'themes/settings/maintenance',
            isWebsiteMaintenance: 'settings/isWebsiteMaintenance',
        }),
        ...mapState({
            operationSetting: (state) => state.settings.operationSetting,
        }),
    },
    watch: {
        operationSetting(value) {
            if (value.website_maintenance) {
                this.dialog = value.website_maintenance?.status
                this.maintenance.description =
                    value.website_maintenance?.description
            }
        },
    },
    mounted() {
        if (this.operationSetting.website_maintenance) {
            this.dialog = this.operationSetting.website_maintenance?.status
            this.maintenance.description =
                this.operationSetting.website_maintenance?.description
        }
    },
}
