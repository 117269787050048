// State
export const state = () => ({
	loading: false,
	transaction: {
		"total": 0,
		"total_amount": 0,
		"totalPages": 0,
		"data": []
	}
})

// Actions
export const actions = {
	// Get transaction history
	async getTransactions({ commit }, params) {
		commit('setLoading', true)
		this.commit('settings/setCancelToken', { transactionHistory: this.$axios.CancelToken.source() })
		try {
			const response = await this.$axios
				.get('/transaction-history', { params, cancelToken: this.state.settings.cancelToken.transactionHistory.token })
			commit('setTransaction', response.data)
		} catch (error) {

		} finally {
			commit('setLoading', false)
		}
	},
}

// Mutations
export const mutations = {
	setLoading(state, data) {
		state.loading = data
	},
	setTransaction(state, data) {
		state.transaction = data
	}
}

// Getters
export const getters = {
	transaction: state => state.transaction
}