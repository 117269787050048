
import cms from '~/mixins/common/cms'
import floatingIcons from '~/mixins/common/floating-icons'
export default {
    name: 'FloatingIcons',
    mixins: [cms, floatingIcons],
    created() {
        this.$nuxt.$on('@toggleMessage', (val) => {
            this.messageFab = val
        })
    },
    destroyed() {
        this.$nuxt.$off('@toggleMessage')
    },
}
