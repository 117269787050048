import { render, staticRenderFns } from "./NoSupportPage.vue?vue&type=template&id=17f507c0"
import script from "./NoSupportPage.vue?vue&type=script&lang=js"
export * from "./NoSupportPage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonsLoading: require('/app/components/Commons/Loading.vue').default})
