
import cms from '~/mixins/common/cms'
export default {
    name: 'CommonsVerticalFloatingIcons',
    mixins: [cms],
    props: {
        items: {
            type: Array,
            required: true,
            default: () => {},
        },
        componentCode: {
            type: String,
            required: true,
            default: '',
        },
    },
    methods: {
        openNewWindow(link) {
            window.open(
                !link.toLowerCase().startsWith('http')
                    ? 'http://' + link
                    : link,
                'Google',
                'width=550,height=600'
            )
        },
    },
}
