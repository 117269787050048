
export default {
    data: () => ({
        loading: false,
    }),
    mounted() {
        this.loading = true
        if (this.$route?.query?.url) {
            const url = this.$route?.query?.url
            window.location.href = !url.toLowerCase().startsWith('http')
                ? 'http://' + url
                : url
        }
    },
}
