
import QrcodeVue from 'qrcode.vue'

export default {
    name: 'CustomQrCode',
    components: {
        QrcodeVue,
    },
    props: {
        value: {
            type: String,
            default: '',
        },
        size: {
            type: [Number, String],
            default: 120,
        },
        background: {
            type: String,
            default: '#ffffff',
        },
    },
}
