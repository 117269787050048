
import { mapGetters } from 'vuex'
import cms from '~/mixins/common/cms'
import playGames from '~/mixins/common/play-games'
export default {
    mixins: [cms, playGames],
    data() {
        return {
            slider: 0,
            sliderItemHeight: 60,
            sliders: [1, 2, 3, 4],
            sliderWidth: 733,
            sliderHeight: 250,
            subSliderModel: 0,
        }
    },
    computed: {
        imageWidth() {
            return Math.floor((this.sliderItemHeight * 1320) / 450) - 48
        },
        ...mapGetters({
            desktopSlider: 'cms/desktopSlider',
        }),
        subSliderContentItemInternal() {
            const totalSliderContentLength = this.getVisibleItems(
                this.desktopSlider?.items
            ).length
            const sliderInterval = 5000
            if (totalSliderContentLength <= 4) return sliderInterval
            return 3 * sliderInterval
        },
        subSliderContentSliderCount() {
            const totalSliderContentLength = this.getVisibleItems(
                this.desktopSlider?.items
            ).length
            if (totalSliderContentLength <= 4) return 1
            if (totalSliderContentLength <= 8) return 2
            return 3
        },
        getSubSliderContentBySliderIndex() {
            const sliderContent = this.getVisibleItems(
                this.desktopSlider?.items
            )
            return (v) => {
                const selectedSliderContent = sliderContent.slice(
                    v * 4,
                    v * 4 + 4
                )
                return selectedSliderContent
            }
        },
        sliderColLength() {
            const sliderContent = this.getSubSliderContentBySliderIndex(
                this.subSliderModel
            )?.length
            if (sliderContent === 4) return 3
            if (sliderContent === 3) return 4
            if (sliderContent === 2) return 6
            return 12
        },
    },
    watch: {
        slider(value) {
            if (value > 7) this.subSliderModel = 2
            else if (value < 4) this.subSliderModel = 0
            else this.subSliderModel = 1
        },
    },
    methods: {
        getSliderImageWidth() {
            const client = process.client
            if (client) {
                // Get slider width when size change
                const val = this.$refs?.refSlider?.clientWidth
                this.sliderWidth = val
                if (val) {
                    // Calculate slider height on ratio 1320:450
                    const height = Math.floor((val * 450) / 1320)
                    this.sliderHeight = height
                    // Display only 4 card if more than that should scrool down
                    this.sliderItemHeight = Math.ceil(height / 4) - 10
                }
            } else {
                setTimeout(() => {
                    this.getSliderImageWidth()
                }, 1000)
            }
        },
    },
}
