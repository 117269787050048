
import { mapGetters } from 'vuex'
export default {
    name: 'AnnouncementText',
    computed: {
        ...mapGetters({
            announcement: 'cms/announcement',
        }),
        announcements() {
            let anouncement = ''
            const anouns = this.announcement
            let idx = 0
            for (const a of this.getVisibleItems(anouns?.items)) {
                idx += 1
                anouncement += `${idx}) ${this.getLocaleLang(a.title)} `
            }
            return anouncement
        },
    },
}
