
export default {
    name: 'StraightComponent',
    props: {
        height: {
            type: [Number, String],
            default: 100,
        },
        width: {
            type: [Number, String],
            default: 200,
        },
        color: {
            type: String,
            default: 'rgba(0, 0, 0, 12%)',
        },
        animation: {
            type: String,
            default: 'wave',
        },
        type: {
            type: String,
            default: 'rect',
        },
        rounded: {
            type: Boolean,
            default: false,
        },
        radius: {
            type: [Number, String],
            default: 4,
        },
    },
    computed: {
        style() {
            return {
                width:
                    typeof this.width === 'number'
                        ? `${this.width}px`
                        : this.width,
                height:
                    typeof this.width === 'number'
                        ? `${this.height}px`
                        : this.height,
                'background-color': `${this.color}`,
                'border-radius': this.rounded ? `${this.radius}px` : 0,
            }
        },
        bindClass() {
            return `animation--${this.animation} shape--${this.type} shape--${
                this.rounded ? 'round' : 'flat'
            }`
        },
    },
    mounted() {
        const width =
            typeof this.width === 'number' ? `${this.width}px` : this.width
        const height =
            typeof this.width === 'number' ? `${this.height}px` : this.height
        const background = `${this.color}`
        const borderRadius = this.rounded ? `${this.radius}px` : 0
        const loader = this.$el
        loader.style.setProperty('width', width)
        loader.style.setProperty('height', height)
        loader.style.setProperty('background-color', background)
        loader.style.setProperty('border-radius', borderRadius)
    },
}
