export default {
    head() {
        const domain = `https://${this.$config.DOMAIN}`
        const fullPath = this.$route.fullPath
        return {
            title: this.$config.META_TITLE,
            link: [{ rel: 'canonical', href: `${domain}${fullPath}` }],
            meta: [
                { name: 'author', content: this.$config.DOMAIN },
                {
                    hid: 'og:title',
                    name: 'og:title',
                    content: this.$config.META_TITLE, // This can be overridden by page-level meta tags.
                },
                {
                    hid: 'description',
                    name: 'description',
                    content: this.$config.META_DESCRIPTION, // This can be overridden by page-level meta tags.
                },
                {
                    hid: 'og:description',
                    name: 'og:description',
                    content: this.$config.META_DESCRIPTION, // This can be overridden by page-level meta tags.
                },
                {
                    hid: 'og:url',
                    name: 'og:url',
                    content: `${domain}${fullPath}`,
                },
            ],
        }
    },
}
