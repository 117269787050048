
import playGames from '~/mixins/common/play-games'
export default {
    name: 'GameIframeEmbedComponent',
    mixins: [playGames],
    props: {
        url: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            loaded: false,
        }
    },
    computed: {
        params() {
            return this.$route.query
        },
    },
    mounted() {
        this.$nuxt.$on('openGameNewTab', async () => {
            const param = JSON.parse(JSON.stringify(this.params))
            delete param.icon
            const res = await this.$store.dispatch(
                'games/playGame',
                {
                    ...param,
                },
                false
            )
            if (res.status && res.url) {
                this.openNewBrowserTab(res.url)
                this.$router.push({
                    path: this.isMobileOnly() ? '/games' : '/',
                })
            }
        })
    },
    destroyed() {
        this.$nuxt.$off('openGameNewTab')
    },
    methods: {
        loadFinish(evt) {
            this.loaded = true
            this.$emit('load', evt)
        },
    },
}
