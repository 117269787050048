
import payment from '../../mixins/common/payment'
export default {
    name: 'CurrencyTextfield',
    mixins: [payment],
    props: {
        value: {
            type: [String, Number],
            default: null,
        },
        label: {
            type: String,
            default: '',
        },
        dense: {
            type: Boolean,
            default: true,
        },
        outlined: {
            type: Boolean,
            default: false,
        },
        hideDetails: {
            type: [Boolean, String],
            default: undefined,
        },
        rules: {
            type: Array,
            default: () => {},
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        customNumber: {
            get() {
                return this.customFormatNumber(
                    this.value?.toString()?.replace(/,/g, '')
                )
            },
            set(value) {
                if (isNaN(value?.toString()?.replace(/,/g, ''))) {
                    this.$emit('input', 0)
                    return
                }

                this.$emit('input', value?.toString()?.replace(/,/g, ''))
            },
        },
    },
}
