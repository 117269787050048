
export default {
    props: {
        registrationOption: {
            type: Number,
            default: 1,
        },
        currency: {
            type: String,
            default: '',
        },
    },
}
