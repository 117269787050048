
export default {
    props: {
        rules: {
            type: Array,
            default: () => [],
        },
        label: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        },
        date: {
            type: String,
            default: '',
        },
        time: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        allowTime: {
            type: Boolean,
            default: true,
        },
        outlined: {
            type: Boolean,
            default: true,
        },
        filled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            dialog: false,
            tabs: null,
            slDate: null,
            selectedDate: this.date,
            selectedTime: this.time,
        }
    },
    watch: {
        date(v) {
            this.slDate = this.$dayjs(v).format(
                this.allowTime ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD '
            )
        },
    },
    mounted() {
        if (this.selectedDate) {
            this.slDate = this.$dayjs(this.selectedDate).format(
                this.allowTime ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD '
            )
        }
    },
    methods: {
        save(value) {
            this.$refs.dialogDateAndTime.save(value)

            this.selectedDate = `${this.$dayjs(this.selectedDate).format(
                'YYYY-MM-DD'
            )} ${this.selectedTime ? this.selectedTime : this.time}`
            this.slDate = this.$dayjs(this.selectedDate).format(
                this.allowTime ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD'
            )
            this.$emit('update', `${this.selectedDate}`)
        },
    },
}
