import { render, staticRenderFns } from "./Cashier.vue?vue&type=template&id=3beb013a&scoped=true"
import script from "./Cashier.vue?vue&type=script&lang=js"
export * from "./Cashier.vue?vue&type=script&lang=js"
import style0 from "./Cashier.vue?vue&type=style&index=0&id=3beb013a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3beb013a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Theme2AvatarIcon: require('/app/components/Theme2/AvatarIcon.vue').default,Theme2OnHoldBalanceCard: require('/app/components/Theme2/OnHoldBalanceCard.vue').default})
