
import { mapGetters } from 'vuex'
import cms from '~/mixins/common/cms'
export default {
    mixins: [cms],
    data() {
        return {
            imageHeight: 0,
        }
    },
    computed: {
        slider() {
            return this.$store.state.settings.slider
        },
        ...mapGetters({
            inviteAndEarnBanner: 'cms/inviteAndEarnBanner',
        }),
    },
    methods: {
        onResize() {
            const screenWidth = window.innerWidth
            // since the slider size is 320x200, so I have an formula to calculate the size:
            const height = (screenWidth * 200) / 320

            this.imageWidth = screenWidth
            this.imageHeight = height
        },
    },
}
