import { PLAYER_REGEX } from '~/utils/constants'

// State
export const state = () => ({
    loading: false,
    rejectAllApiRequests: false,
})
// Actions
export const actions = {
    async registration({ dispatch, rootState }, data) {
        if (data.player_id) {
            if (!PLAYER_REGEX.test(data.player_id)) return
        }
        if (rootState.themes.settings.isInternational) {
            const response = await dispatch(
                'registrationForInternational',
                data
            )
            return response
        }
        const response = await dispatch('registrationForLao', data)
        return response
    },
    async registrationForLao(
        { commit, dispatch, rootState, rootGetters },
        data
    ) {
        commit('loading', true)
        const form = JSON.parse(JSON.stringify(data))

        delete data.bankOptionSelection
        // Delete bankOptionSelection key, it's just telling us to know which bankOptionSelection the player is selecting
        const recaptchaToken = form.recaptchaToken
        delete form.recaptchaToken
        const rememberMe = form.rememberMe
        delete form.rememberMe

        // check referral types
        const referral = this.$cookie.get('referral')
        const previousUrl = this.$cookie.get('previous_url')
        const bannerCookieId = this.$cookie.get('banner_cookie_id')
        const bannerId = this.$cookie.get('banner_uuid') // This cookie is from /visit route
        const bannerUrl = this.$cookie.get('banner_url') // This cookie is from /visit route

        let referralId = null
        let referralType = null
        let referralCurrency = ''

        if (referral) {
            const { type, userId, currency } = referral
            referralId = userId
            referralCurrency = currency
            if (type === 'u') referralType = 'player'
            else if (type === 'a') referralType = 'agent'
        }

        // Logic
        // If the registered currency is not matched with the referral currency, then the player will be normal player
        // If they are matched, the player will be referral player under the player who generated the link;
        const isReferralAndRegisteredCurrencyMatched =
            referralCurrency?.toLowerCase() === data?.currency?.toLowerCase()

        const headers = {
            'Content-Type': 'Application/json',
            'auth-key': recaptchaToken,
        }
        if (
            referralId &&
            referralType &&
            isReferralAndRegisteredCurrencyMatched === true
        ) {
            headers.Cookies = `referral_id=${referralId}; referral_type=${referralType}`
        }
        if (previousUrl) {
            headers['X-AFFILIATE-URL'] = previousUrl
        }
        if (bannerCookieId) {
            headers['X-BANNER-ID'] = bannerCookieId
        }
        if (bannerId) {
            headers['X-BANNER-ID'] = bannerId
            if (bannerUrl) {
                headers['X-BANNER-URL'] = bannerUrl
            }
        }
        form.language = rootGetters['base-settings/getWhiteLabelLocale']
        let url = 'players/register'
        // is international
        if (data?.reg_flow) {
            url = `/registration/option/${data.reg_flow}`

            if ([2, 3, 4, 5].includes(data.reg_flow)) {
                delete form.bank_id
                delete form.bank_account_number
                delete form.bank_currency
                delete form.bank_branch

                if ([4, 5].includes(data.reg_flow)) {
                    delete form.account_holder_name
                }
            }
            delete form.reg_flow
        }
        // is lao white label
        if (data?.registrationOption) {
            url = `/players/register`
            if ([2, 3, 4].includes(data.registrationOption)) {
                url = `/players/register-without-bank`
                delete form.bank_id
                delete form.bank_account_number
                delete form.bank_currency

                if (data.registrationOption === 4) {
                    delete form.account_holder_name
                }
            }
            delete form.registrationOption
        }

        delete form.country

        const redirect = rootState.themes.settings.isMobile ? '/games' : '/'

        const res = await this.$axios
            .post(url, form, {
                headers,
            })
            .then(async (response) => {
                if (response.status === 201) {
                    await this.$cookie.set('token', response.headers.token, {
                        path: '/',
                        maxAge: 60 * 60 * 3 - 60,
                    })

                    await this.$cookie.set('player', response.data)
                    this.$cookie.set('currency', response.data.currency)
                    await this.commit('player/setPlayer', response.data)
                    this.$cookie.set(
                        'country_code',
                        response?.data?.country_code
                    )

                    await this.dispatch('settings/toggleIsLogin', true, {
                        root: true,
                    })
                    await this.dispatch('player/totalBalance', true, {
                        root: true,
                    })

                    setRegisterRememberMe(this.$cookie, {
                        playerId: response.data.player_id,
                        phone: response.data.phone,
                        rememberMe,
                    })
                    // Success
                    commit('loading', false)
                    this.$toast.success(
                        this.getters['themes/language-string/tl'](
                            'registerSuccessful'
                        )
                    )
                    if (
                        isReferralAndRegisteredCurrencyMatched === true &&
                        referralId
                    ) {
                        await this.$cookie.remove('referral')
                    }
                    await this.$cookie.remove('previous_url')
                    await this.$cookie.remove('banner_cookie_id')
                    await this.$cookie.remove('banner_uuid')
                    await this.$cookie.remove('banner_url')
                    if (this.$cookie.get('banner_id')) {
                        this.$cookie.remove('banner_id')
                    }
                    dispatch('themes/player-bank/getBankPlayerAccount', true, {
                        root: true,
                    })
                    dispatch('player/getPlayerInviteAndEarnDomain', true, {
                        root: true,
                    })
                    // this.$router.push('/player/deposits')
                    return true
                }
            })
            .catch((e) => {
                commit('loading', false)
            })

        return { redirect, res }
    },
    async registrationForInternational(
        { commit, dispatch, rootState, rootGetters },
        data
    ) {
        const registrationOption = data.reg_flow
        const recaptchaToken = data.recaptchaToken
        const isRememberMe = data.rememberMe
        const bankOptionSelection = data.bankOptionSelection

        // Default Form for registration
        const form = {
            password: data.password,
            phone: data.phone,
            currency: data.currency,
            language: rootGetters['base-settings/getWhiteLabelLocale'],
            country_code: data.country_code,
            reg_fingerprint: data.reg_fingerprint,
            player_id: data.player_id,
        }

        // Check Registration Option to Send the Required Form Body
        if (registrationOption === 1) {
            form.bank_id = data.bank_id
            form.bank_name = data.bank_name

            // If bank option is Bank List then delete the Manually Bank Name
            if (bankOptionSelection === 1) delete form.bank_name
            else delete form.bank_id
            form.bank_account_number = data.bank_account_number
            form.bank_currency = data.bank_currency
            form.account_holder_name = data.account_holder_name
            form.bank_branch = data.bank_branch
        } else if (registrationOption === 2 || registrationOption === 3) {
            form.account_holder_name = data.account_holder_name
        }

        const defaultWebsiteDataFromDefaultAPI =
            rootState.settings.defaultWebsiteData
        const xBannerId = this.$cookie.get('x-banner-id') // It was set by /visit or /default api
        const xBannerUrl = this.$cookie.get('x-banner-url') // It was set by banner redirection link
        const xPlayerType = this.$cookie.get('x-player-type') // It was set by /visit or /default api
        const referral = this.$cookie.get('referral') // It was set by invite and earn link
        const previousUrl = this.$cookie.get('x-referral-url') // It was set by JS document.referral

        // Header Request Form
        const userPlatform = rootState.themes.settings.isMobile
            ? 'mobile'
            : rootState.app.isApp.detected
            ? 'app'
            : 'desktop'
        const headers = {
            'x-platform': userPlatform,
        }
        if (defaultWebsiteDataFromDefaultAPI.recaptcha_status)
            headers['auth-key'] = recaptchaToken
        if (defaultWebsiteDataFromDefaultAPI['x-domain-name'])
            headers['X-DOMAIN-NAME'] =
                defaultWebsiteDataFromDefaultAPI['x-domain-name']
        if (xPlayerType) headers['X-PLAYER-TYPE'] = xPlayerType
        if (previousUrl) headers['X-AFFILIATE-URL'] = previousUrl
        if (xBannerId) headers['X-BANNER-ID'] = xBannerId

        // Checking referral condition [invite-and-earn link]
        if (referral) {
            const { type, userId, currency } = referral
            const referralType =
                type === 'u' ? 'player' : type === 'a' ? 'agent' : ''
            if (
                currency?.toLowerCase() === form.currency?.toLowerCase() &&
                userId &&
                referralType
            ) {
                headers['X-REFERRAL-ID'] = userId
                headers['X-REFERRAL-CURRENCY'] = currency
            }
        }

        // x-banner-url header should not be present if it's invite-and-earn link
        if (xBannerUrl && !headers['X-REFERRAL-ID'])
            headers['X-BANNER-URL'] = xBannerUrl

        const redirectPageAfterRegistration = rootState.themes.settings.isMobile
            ? '/games'
            : '/'
        const res = await this.$axios
            .post(`/registration/option/${registrationOption}`, form, {
                headers,
            })
            .then(async (response) => {
                await this.$cookie.set('token', response.headers.token, {
                    path: '/',
                    maxAge: 60 * 60 * 3 - 60,
                })
                await this.commit('player/setPlayer', response.data)
                await this.$cookie.set('player', response.data)
                this.$cookie.set('currency', response.data.currency)
                this.$cookie.set('country_code', response?.data?.country_code)
                await this.dispatch('settings/toggleIsLogin', true, {
                    root: true,
                })
                await this.dispatch('player/totalBalance', true, { root: true })

                // To set and remove rememberMe data
                setRegisterRememberMe(this.$cookie, {
                    playerId: response.data.player_id,
                    phone: response.data.phone,
                    rememberMe: isRememberMe,
                })

                this.$toast.success(
                    this.getters['themes/language-string/tl'](
                        'registerSuccessful'
                    )
                )
                this.$cookie.remove('referral')
                this.$cookie.remove('previous_url')
                this.$cookie.remove('x-banner-id')
                this.$cookie.remove('x-banner-url')
                this.$cookie.remove('x-player-type')

                dispatch('themes/player-bank/getBankPlayerAccount', true, {
                    root: true,
                })
                // To get the invite and earn domain link
                dispatch('player/getPlayerInviteAndEarnDomain', true, {
                    root: true,
                })
                return true
            })
            .catch((e) => {
                // If the website is under maintenance during registration
                if (e?.response?.data?.website_maintenance) {
                    this.$router.go('/')
                }
                return false
            })
            .finally(() => {
                commit('loading', false)
            })
        return { redirect: redirectPageAfterRegistration, res }
    },
    // login
    async login({ commit, dispatch, rootState }, form) {
        commit('loading', true)
        const defaultWebsiteDataFromDefaultAPI =
            rootState.settings.defaultWebsiteData

        const recaptchaToken = form.recaptchaToken
        delete form.recaptchaToken
        const rememberMe = form.rememberMe
        delete form.rememberMe

        const userPlatform = rootState.themes.settings.isMobile
            ? 'mobile'
            : rootState.app.isApp.detected
            ? 'app'
            : 'desktop'
        const headers = {
            'x-platform': userPlatform,
        }

        // auth-key will be always required for lao client
        // For international: check by /default api
        if (
            defaultWebsiteDataFromDefaultAPI.recaptcha_status ||
            !rootState.themes.settings.isInternational
        )
            headers['auth-key'] = recaptchaToken

        await this.$axios
            .post(`/players/login`, form, {
                headers,
            })
            .then((response) => {
                if (response.status === 200) {
                    this.$cookie.set('token', response.headers.token, {
                        path: '/',
                        maxAge: 60 * 60 * 3 - 60,
                    })
                    dispatch(
                        'base-settings/updateLocaleAndSetCookie',
                        response.data.language,
                        { root: true }
                    )
                    this.$cookie.set('player', response.data)
                    this.$cookie.set('currency', response.data.currency)
                    this.$toast.success(
                        this.getters['themes/language-string/tl'](
                            'loginSuccessful'
                        )
                    )

                    if (form.player_id) {
                        if (rememberMe)
                            this.$cookie.set('un', form.player_id, {
                                path: '/',
                                maxAge: 60 * 60 * 24 * 30 - 60,
                            })
                        else this.$cookie.remove('un')
                    } else if (form.phone) {
                        if (rememberMe)
                            this.$cookie.set('pn', form.phone, {
                                path: '/',
                                maxAge: 60 * 60 * 24 * 30 - 60,
                            })
                        else this.$cookie.remove('pn')
                    }

                    this.$router.go('/')
                }
            })
            .catch((e) => {
                // If the website is under maintenance during login
                if (e?.response?.data?.website_maintenance) {
                    this.$router.go('/')
                }
                commit('loading', false)
            })
    },
    async logout({ commit, dispatch }) {
        try {
            if (this.$cookie.get('token')) {
                await this.$axios.put('/players/logout').finally(() => {
                    this.$cookie.remove('token')
                    dispatch('settings/toggleIsLogin', false, { root: true })
                    this.$toast.success(
                        this.getters['themes/language-string/tl'](
                            'logoutSuccessful'
                        )
                    )
                    this.$cookie.remove('player')

                    this.$router.push('/')
                    // Since the $router is not working inside this async, need to use JS build-in window
                    window.location.reload()
                })
            }
        } catch (error) {}
    },
}
// Mutations
export const mutations = {
    loading: (state, v) => {
        state.loading = v
    },
    setRejectAllApiRequests: (state, v) => {
        state.rejectAllApiRequests = v
    },
}

function setRegisterRememberMe(cookie, { playerId, phone, rememberMe }) {
    if (rememberMe) {
        cookie.set('un', playerId, {
            path: '/',
            maxAge: 60 * 60 * 24 * 30 - 60,
        })
        cookie.set('pn', phone, {
            path: '/',
            maxAge: 60 * 60 * 24 * 30 - 60,
        })
    } else {
        cookie.remove('un')
        cookie.remove('pn')
    }
}
